import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import NotFount from './components/NotFount';
import About from './pages/About';
import Blogs from './pages/Blogs';
import SingleBlog from './pages/SingleBlog';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import * as React from "react";
import Faq from './pages/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import ACarousel from './components/utils/AliceCarousel';
import Unsubscribe from './components/Unsubscribe';
const AppLayout = () => (
  <>
    <Header />
    <main >
      <div className='container-xxl mt-5 pt-5 px-0 d-flex flex-column'>
        <Outlet />
      </div>
    </main>
    <Footer />
  </>
);
const routesConfig = [
  {
    element: <AppLayout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "about-us",
        element: <About />,
      },
      {
        path: "faq",
        element: <Faq />,
      },
      {
        path: "blogs/all",
        element: <Blogs type="all"/>,
      },
      {
        path: "blogs/workout",
        element: <Blogs type="workout" />,
      },
      {
        path: "blogs/recipe",
        element: <Blogs type="recipe" />,
      },
      {
        path: "blogs/health",
        element: <Blogs type="health" />,
      },
      {
        path: "blogs/:category/:title/:id",
        element: <SingleBlog />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms-and-conditions",
        element: <Terms />,
      },
      {
        path: "test",
        element: <ACarousel />,
      }
    ],
  },
  {
    path: "*",
    element: <NotFount />,
  },
  {
    path: "/unsubscribe/:id",
    element: <Unsubscribe />,
  }
];
const router = createBrowserRouter(routesConfig);
const App = () => {
  React.useEffect(()=>{
    window.onload = ()=>{
      let links = document.querySelectorAll('a[href*=play\\.google\\.com]');
      if(/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        links.forEach(x=>{
          x.setAttribute('href', 'https://apps.apple.com/pk/app/myfitnesscoach-diet-workout/id1670136195')
        })
      }
    }
  },[])
  return <RouterProvider router={router} />;
};
export default App;
