import * as React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import "./AliceCarousel.css"
import { useEffect } from 'react';

export default function ACarousel(props) {
    const [data, setData] = React.useState([])
    const { items, showDots, hideNav } = props;
    useEffect(() => {
        setData(props.data)
    }, [props])
    return (
        <AliceCarousel mouseTracking items={data} paddingLeft={'4px'}
            autoPlay={true} autoPlayInterval={3000} animationDuration={400} mouseDrag={false}
            disableButtonsControls={hideNav?true:false}
            speed={200} infinite={true} dotsSpeed={500} disableDotsControls={showDots?false:true}
            touchTracking={false}

            responsive={{
                0: { items: items?items:1 },
                768: { items: items?items:2, itemsFit: 'contain' },
                1200: { items: items?items:3 },
            }}
        />
    );
}
