import React, { useEffect, useState } from "react";
import "./FAQ.css"
import { GlobalMethods } from "../services/GlobalMethods";
import { Autocomplete, TextField } from "@mui/material";
import MetaTags from "../components/utils/MetaTags";


var filtered_questions = [];
export default function Faq() {
    GlobalMethods.current_sec = "faq-section"
    const methods = new GlobalMethods()
    const [search_text, setSearch_text] = useState("");
    const handleInput = (event, value) => {
        setSearch_text(value);
        if (value) {
            filtered_questions = Questions.filter(x => JSON.stringify(x).includes(value))
        } else {
            filtered_questions = Questions;
        }
    }
    useEffect(() => {
        window.onload = ()=>{
            methods.snapTo('faq-section')
        }
    }, [])
    return (
        <>
            <MetaTags text="MyFitnessCoach | My Fitness Coach" />
            <section id="faq-section">
                <div className="faq-content-wraper px-3">
                    <div className="faq-section d-flex flex-column rg">
                        <h2 className="blogs-h2 faq-title">Frequently Asked Questions</h2>
                        <p className="faq-sub-title">Have questions? We are here to help.</p>
                        <div className="d-flex justify-content-center">
                            <div className="faq-search-wraper d-flex">
                                <span className="faq-search-icon border-0 cursor-pointer" id=""
                                    onClick={(event) => { handleInput(event, document.querySelector("#search_input").value) }}>
                                    <img  src="../assets/search-normal.png" alt="search icon" />
                                </span>
                                {/* <input type="search " className="border-0 w-100" placeholder="Search" aria-label="Search" aria-describedby="" /> */}
                                <Autocomplete
                                    className="custom-search"
                                    id="search_input"
                                    freeSolo
                                    placeholder="Search"
                                    options={Questions && Questions?.map(x => x.question)}
                                    renderInput={(params) => <TextField {...params} label="Search" />}
                                    onChange={handleInput}
                                />
                            </div>
                        </div>
                        <div className="accordion w-100 pt-5" id="basicAccordion">
                            {
                                filtered_questions?.map((q, i) => (
                                    <div className="accordion-item" key={i}>
                                        <h3 className="accordion-header" id={"heading" + i}>
                                            <button className="accordion-button collapsed faq-head" type="button" data-bs-toggle="collapse"
                                                data-bs-target={"#basicAccordionCollapse" + i} aria-expanded="false" aria-controls={"collapse" + i}>
                                                {q.question}
                                            </button>
                                        </h3>
                                        <div id={"basicAccordionCollapse" + i} className="accordion-collapse collapse" aria-labelledby={"heading" + i}
                                            data-bs-parent="#basicAccordion" >
                                            <div className="accordion-body faq-ans">
                                                {q.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}


const Questions = [
    {
        "question": "What is MyFitnessCoach?",
        "answer": "MyFitnessCoach app is a mobile application designed to help individuals with their health and fitness goals. It offers a wide range of features, including personalized workout plans, comprehensive exercise demonstrations, nutrition guidance with interesting meal recipes, and progress tracking."
    },
    {
        "question": "How can I download the MyFitnessCoach app?",
        "answer": "You can simply download the MyFitnessCoach app from your mobile device's app store. It is available for both iOS and Android platforms."
    },
    {
        "question": "How does MyFitnessCoach work? Or How can I use MyFitnessCoach app?",
        "answer": "To get started with MyFitnessCoach, you'll need to create an account and answer a few questions about your fitness level, goals, and schedule. MyFitnessCoach will then use this information to create a personalized workout plan and nutrition Plan for you."
    },
    {
        "question": "Is the MyFitnessCoach app free? Or How much does the app cost?",
        "answer": "MyFitnessCoach has a free version available with limited features. There are also some advance level premium features that require a monthly subscription. It allows you to have access to all of its features."
    },
    {
        "question": "Is MyfitnessCoach app safe? or How safe is the app?",
        "answer": "MyFitnesscoach app is completely safe and secure. Your personal data is protected by industry-standard encryption. Moreover, MyFitnesscoach does not share any of its users’ data with any third party."
    },
    {
        "question": "How can I track my workouts using the 'MyFitnessCoach' app?",
        "answer": "MyFitnessCoach allows you to track your workouts. You can choose from pre-designed workout plans or create your own. It often includes features such as exercise logging, reps, and set tracking."
    },
    {
        "question": "Does the MyFitnessCoach app provide nutrition guidance? or How can I get diet plans? ",
        "answer": "Yes, the app often provides nutrition guidance. It may offer meal-tracking features where you can log your food intake, count calories, and track macronutrients. Some versions of the app may also offer meal plans, recipes, and nutrition tips to support your health and fitness goals."
    },
    {
        "question": "Can I customize my workout plans in the MyFitnessCoach app?",
        "answer": "Yes, many fitness apps, including MyFitnessCoach, allow you to customize your workout plans. You can often choose exercises based on your preferences and fitness level."
    },
    {
        "question": "Is there customer support available for the MyFitnessCoach app? Or How can I ask queries on MyFitnessCoach?",
        "answer": "MyFitnessCoach offers customer support through email, chat, or online forums to address any issues, answer questions, or provide assistance with using the app. In the near future, we have a plan to plug in Chatbot in the app to provide real-time customer support."
    },
    {
        "question": "What if I don't like my workout plan? Or Can I change my workout plan?",
        "answer": "If you don't like your workout plan, you can always change it. MyFitnessCoach allows you to customize your workout plan according to your needs and preferences."
    },
    {
        "question": "What if I have a question about my nutrition guidance? Or How can I ask questions about nutrition? ",
        "answer": "If you have a question about your nutrition guidance, you can always contact MyFitnessCoach customer support. You will get the proper guidance from nutrition experts via email."
    },
    {
        "question": "What are the system requirements for MyFitnessCoach?",
        "answer": "MyFitnessCoach is compatible with almost every type of smartphone. You can find the system requirements for MyFitnessCoach on the MyFitnessCoach website."
    },
    {
        "question": "What if I miss/skip a workout?",
        "answer": "If you miss a workout, you don’t need to worry at all. You can always catch up where you left off. MyFitnessCoach will track your progress even if you miss a workout or two."
    },
    {
        "question": "What if I get injured?",
        "answer": "If you get injured, you should immediately stop working out and consult with a doctor or physical therapist. MyFitnessCoach provides effective visual demonstrations of all exercises which minimizes the risk of injury. All you need to do is to properly follow the workout guidance."
    }
]

filtered_questions = Questions;