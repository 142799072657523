import React, { useEffect } from 'react';
import '../pages/About.css';
import { GlobalMethods } from '../services/GlobalMethods';
import SendEmail from '../components/utils/SendEmail';
import MetaTags from '../components/utils/MetaTags';


export default function About() {
    const methods = new GlobalMethods()
    GlobalMethods.current_sec = "about-us-section";
    useEffect(() => {
        setTimeout(() => {
            methods.snapTo(GlobalMethods.current_sec)
        }, 500);
    }, [])
    return (
        <>
            <MetaTags text="MyFitnessCoach | About Us" />
            <main className="px-responsive-5">
                <section id="about-us-section" className='about-who-we-are'>
                    <div className='about-sec1-title d-flex flex-column align-items-center'>
                        <h2 className='hero-sub-heading text-center'>
                            Who We Are
                        </h2>
                        <div className='about-description w-50 d-flex align-items-center'>
                            <p className='plan-txt-sm-black text-center'>
                                <a href='https://myfitnesscoach.fit/' target='_blank'> MyFitnessCoach</a> is your personal trainer to help you achieve better health and a fitter life as quickly and effortlessly as possible. We make your goals our goal!</p>
                        </div>
                    </div>
                    <div className='about-body1-wraper d-flex align-items-center justify-content-center pt-5 cg'>
                        <div className='about-left col-lg-6 col-md-12'>
                            <div className='left-top d-flex flex-column'>
                                <h2 className='card-inner-heading'>
                                    Our Mission And  Vision
                                </h2>
                                <span className='stepper-row-line'></span>
                                <p className='plan-txt-md pt-4'>At MyFitnessCoach, our mission and vision are simple: empower individuals to take charge of their health through fitness, nutrition, and healthy lifestyles. <br />
                                    Our belief is that all individuals from any background and fitness level deserve access to tailored fitness tools.
                                </p>
                            </div>
                            <div className='left-bottom pt-5'>
                                <button type='button' className='btn btn-outline-primary about-sec1-button'
                                    onClick={() => { document.querySelector("#subscription-section").scrollIntoView(true) }}>Get in touch</button>
                            </div>
                        </div>
                        <div className='about-right col-lg-6 col-md-12 '>
                            <img  className='w-100' src='../assets/about-hero.webp' alt='girl doing workout' />
                        </div>
                    </div>
                </section>
                <section className='about-why-choose-us mt-5rem'>
                    <div className='about-sec2-title d-flex flex-column align-items-center'>
                        <h2 className='hero-sub-heading text-center'>
                            Why Choose Us
                        </h2>
                        <div className='about-description2 w-50 d-flex align-items-center'>
                            <p className='plan-txt-sm-black text-center'>Our experienced fitness instructors are enthusiastic about helping you reach the fitness targets set out by you.</p>
                        </div>
                        <div className='about-body2-wraper d-flex flex-wrap mt-5rem' >
                            <div className="about-sec2-card  position-relative">
                                <div className="sec2-card-logo d-flex flex-column">
                                    <img  className="position-absolute diet-plan-about" src="../assets/diet-plan-about.svg" alt='diet plans logo' />
                                </div>
                                <h4 className="app-titleh">Diet Plans</h4>
                                <p className="plan-txt-xsm2">MyFitnessCoach develops custom fitness and diet plans tailored specifically to the body type of each user, to ensure optimal results. By taking each person's unique requirements into account, MyFitnessCoach ensures every effort is taken towards meeting those goals successfully.</p>
                            </div>
                            <div className="about-sec2-card  position-relative">
                                <div className="sec2-card-logo d-flex flex-column">
                                    <img  className="position-absolute focused-about" src="../assets/focused-about.svg" alt='dumble in hand' />
                                </div>
                                <h4 className="app-titleh">Focused Workouts</h4>
                                <p className="plan-txt-xsm2">MyFitnessCoach allows users to choose from an extensive library of workouts while keeping an eye on their progress with real-time statistics.</p>
                            </div>
                            <div className="about-sec2-card  position-relative">
                                <div className="sec2-card-logo d-flex flex-column">
                                    <img  className="position-absolute focused-about" src="../assets/healthy-recipe-about.svg" alt='dumble in hand' />
                                </div>
                                <h4 className="app-titleh">Explore 950+ Healthy Recipes</h4>
                                <p className="plan-txt-xsm2">With recipes designed to meet different dietary preferences, whether you want to follow ketogenic, vegetarian or dairy free diet, MFC has got you covered</p>
                            </div>
                            <div className="about-sec2-card  position-relative">
                                <div className="sec2-card-logo d-flex flex-column">
                                    <img  className="position-absolute focused-about" src="../assets/food-logger-about.svg" alt='dumble in hand' />
                                </div>
                                <h4 className="app-titleh">Comprehensive & Precise Food Logger</h4>
                                <p className="plan-txt-xsm2">MyFitnessCoach provides functionalities like daliy step counter, food diary
                                    custom meal plans and more.</p>
                            </div>
                            <div className="about-sec2-card  position-relative">
                                <div className="sec2-card-logo d-flex flex-column">
                                    <img  className="position-absolute info-videos-about " src="../assets/info-videos-about.svg" alt='video icon' />
                                </div>
                                <h4 className="app-titleh">Informatory Videos</h4>
                                <p className="plan-txt-xsm2">The app includes several informative videos covering various aspects of fitness, nutrition, and motivation. Users will gain complete information on different exercises as well as tips and motivation to stay active.</p>
                            </div>
                            <div className="about-sec2-card  position-relative">
                                <div className="sec2-card-logo d-flex flex-column">
                                    <img  className="position-absolute step-counter-about" src="../assets/step-counter-about.svg" alt='step counter logo' />
                                </div>
                                <h4 className="app-titleh">Step Counter</h4>
                                <p className="plan-txt-xsm2">MyFitnessCoach step counter feature effectively keeps track of daily steps taken and encourages a healthier lifestyle.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="subscription-section" className='subscription d-flex align-items-center mt-5rem'>
                    <div className='subs-left pe-5 d-flex flex-column rg'>
                        <h2 className='subscribe-heading'>Have Something In Mind</h2>
                        <p className='subscribe-paragraph'>Contact us if you have any queries regarding our app or would like to learn more about our fitness and diet plans. Please contact us and we'll be more than happy to assist you.</p>
                        <span className='pt-5'></span>
                        <SendEmail />
                    </div>
                    <div className='subs-right d-flex justify-content-center'>
                        <img  src='../assets/envelope-email.webp' alt='blue envelope used for subscription' />
                    </div>
                </section>
            </main>
        </>
    )
}