import React, { useEffect, useState } from 'react';
import './Home.css';
import Carousel from './utils/Carousel';
import CustomAccordian from './utils/Accordion';
import VerticalCarousel from './utils/VerticalCarousel';
import { GlobalMethods } from '../services/GlobalMethods';
import SendEmail from './utils/SendEmail';
import MetaTags from './utils/MetaTags';

function Home() {
  const [play, setPlay] = useState("true");
  const [play1, setPlay1] = useState("true");
  const methods = new GlobalMethods();
  if (GlobalMethods.current_sec == "error" ||
    GlobalMethods.current_sec == "error-page") {
    GlobalMethods.current_sec = "hero-section";
  }
  useEffect(() => {
    setTimeout(() => {
      methods.snapTo(GlobalMethods.current_sec)
    }, 500);
  }, [GlobalMethods.current_sec])

  return (
    <>
      <MetaTags text="MyFitnessCoach | My Fitness Coach" />
      <div id="custom-scroll-spy" className="px-3">
        <section id="hero-section" className='hero-sec-homepg'>
          <div className='hero-wraper d-flex flex-row align-items-center'>
            <div className='hero-left col-lg-7 col-md-12 d-flex flex-column pb-4 mb-5 ps-5'>
              <div className='hero-left-top d-flex flex-column rg d-flex flex-column '>
                <div className='hero-title d-flex flex-column position-relative'>
                  <h1 className='app-titleh'>MyFitnessCoach,</h1>
                  <h3 className='hero-heading'>A <span className='hero-heading-blue'>Gateway</span></h3>
                  <h3 className='hero-sub-heading d-flex flex-row flex-wrap'>To a healthy &nbsp;
                    <span className='text-logo d-flex flex-column'>
                      <h3 className='hero-sub-heading'>lifestyle</h3>
                      <img src="../assets/text-logo.svg" alt="underline" className='position-absolute bottom-0' />
                    </span></h3>
                </div>
                <div className='hero-desc-wraper'>
                  <h4 className='hero-description plan-txt-md'>
                    Get the most out of your workouts and diet plans with MyFitnessCoach App
                  </h4>
                </div>
                <div className='hero-buttons-applink d-flex flex-row cg pt-4 '>
                  <a className=' d-flex flex-row align-items-center' href='https://play.google.com/store/apps/details?id=com.myfitnesscoach' target='_blank'>
                    <img src="../assets/google-play-nb.svg" alt='Get it on Google Play Store' />
                  </a>
                  <a className=' d-flex flex-row align-items-center' href='https://apps.apple.com/pk/app/myfitnesscoach-diet-workout/id1670136195' target='_blank'>
                    <img src="../assets/app-store-nb.svg" alt='Get it on App store' />
                  </a>
                </div>
              </div>
              <div className='hero-left-lower d-flex flex-row align-items-center'>
                <div className='hero-count d-flex flex-column align-items-start'>
                  <span className=''>500+</span>
                  <p>Workouts</p>
                </div>
                <div className='hero-count d-flex flex-column align-items-start xborder-card '>
                  <span>100+</span>
                  <p>Info Videos</p>
                </div>
                <div className='hero-count d-flex flex-column align-items-start '>
                  <span>950+</span>
                  <p>Curated Diet Recipes</p>
                </div>
              </div>
            </div>
            <div className='hero-right col-lg-5 col-md-12'>
              <video className='hero-sec-iphone' type="video/mp4" alt="myfitnesscoachapp"
                controls={false} autoPlay={true} muted={true} playsInline={true} loop={true} src='../assets/app-intro-comp.mp4' />
            </div>
          </div>

        </section>
        <section id="intro-section" className='introduction-sec-homepg mt-0'>
          <div className='intro-wraper d-flex flex-row align-items-center'>
            <div className='intro-left col-xl-8 col-lg-12 d-flex flex-column'>
              <div className='intro-top'>
                <img className='intro-top-img' src='../assets/dumbles-cover.png' alt='weight-lifting' />
              </div>
              <div className='intro-mid d-flex flex-column rg'>
                <div className='intro-title d-flex flex-column'>
                  <h3 className='intro-sub-heading'>Introduction to </h3>
                  <h2 className='intro-heading'>MyFitnessCoach</h2>
                </div>
                <div className='intro-description pt-2'>
                  <p className='plan-txt-sm-white text-start'>MyFitnessCoach - your ultimate fitness companion! An all-in-one fitness solution to meet all of your fitness needs beyond just workouts. Our app is designed to help you achieve your fitness goals effectively.</p>
                </div>
              </div>
              <div className='intro-bottom'>
                <img className='intro-bottom-img' src='../assets/food-cover.webp' alt='Healthy Recipes' />
              </div>
            </div>
            <div className='intro-right intro-right-2 col-xl-4 col-lg-12 py-3rem-12 d-flex align-items-center justify-content-center'>
              <div style={{ position: "relative", width: "80%" }}>
                <img src="assets/mobile-frame.webp" className="" style={{ height: "100%", width: "100%" }} alt="" />
                <img src="assets/camera-lense.svg" className='camera-lense' />
                <video className="video-fluid informative-video intro-video-2" src="assets/compressed-video.mp4" type="video/mp4" alt="Fitness Trainer"
                  preload="none" controls={true} autoPlay={true} muted={true} playsInline={true} loop={true}
                />
                <div style={{
                  position: "absolute",
                  color: "#fff",
                  background: "transparent",
                  left: "0",
                  right: "0",
                  top: "0",
                  width: play && play ? '0' : '100%',
                  bottom: "5rem",
                  zIndex: "2",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                  onClick={(event) => {
                    let video_ = document.querySelector(".intro-video-1")
                    video_.paused ? video_.play() : video_.pause();
                    setPlay(!video_.paused)
                  }}>
                  <img src="assets/video-play-button.svg" alt='play button' style={{ display: (play && play ? 'none' : 'block') }} /></div>
              </div>
            </div>
          </div>
        </section>
        <section id="app-working-section" className='app-working d-flex flex-column mt-6rem'>
          <div className='app-working-title w-50 align-self-center pb-2'>
            <h2 className='hero-sub-heading App'>How it works?</h2>
          </div>
          <div className='app-working-description w-50 align-self-center pb-5'>
            <p className='plan-txt-sm-black App'>MyFitnessCoach meal and diet plans are based on user input and tailored to support your fitness journey.</p>
          </div>
          <VerticalCarousel />
        </section>
        <section id="app-features-section" className='app-features mx-auto d-flex flex-column col-11 d-flex flex-column align-self-center mt-6rem'>
          <div className='app-features-title w-75 align-self-center pb-2'>
            <h2 className='hero-sub-heading App'>Our App Features</h2>
          </div>
          <div className='app-features-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Ready to transform your life? We've got you covered! Let the MyFitnessCoach app revolutionize your fitness approach. With a bunch of amazing features, this app is designed to empower, motivate, and guide you. Let's get fit like never before!</p>
          </div>
          <div className='app-features-logo d-flex flex-column align-items-center pt-5'>
            <img className='features-logo1' src='../assets/app-features-logo.png' alt='iphone having myfitnesscoach app' />
            <img className='features-logo2' src='../assets/app-features.svg' alt='app features' />
          </div>
          <div className='features-card-wraper'>
            <div className='feature-card col-md-3 position-relative slide-right'>
              <div className='feature-card-logo d-flex flex-column align-self-center'>
                <img className='position-absolute align-self-center' src='../assets/diet-logo.png' alt='diet plans' />
              </div>
              <h4 className='app-titleh'>Diet Plans</h4>
              <p className='plan-txt-xsm2'>Whether you want to lose weight, gain muscle, or improve your overall health, you can access a personalized diet plan that meets your goals with MyFitnessCoach App</p>
            </div>
            <div className='feature-card col-md-3 position-relative slide-right'>
              <div className='feature-card-logo d-flex flex-column align-self-center'>
                <img className='position-absolute align-self-center' src='../assets/unlimited-recipes.png' alt='unlimited recipes' />
              </div>
              <h4 className='app-titleh'>Unlimited Recipes</h4>
              <p className='plan-txt-xsm2'>Discover tons of delicious and healthy recipes from quick meals to gourmet dishes with MyFitnessCoach App and never get bored of your diet again.</p>
            </div>
            <div className='feature-card col-md-3 position-relative slide-right'>
              <div className='feature-card-logo d-flex flex-column align-self-center'>
                <img className='position-absolute align-self-center' src='../assets/macro-mate.png' alt='my macro mate' />
              </div>
              <h4 className='app-titleh'>My Macro Coach</h4>
              <p className='plan-txt-xsm2'>Keep track of your macronutrients with the My Macro Mate feature, which helps you maintain an optimal balance of carbs, protein, and fat.</p>
            </div>
            <div className='feature-card col-md-3 position-relative slide-left'>
              <div className='feature-card-logo d-flex flex-column align-self-center'>
                <img className='position-absolute align-self-center' src='../assets/focused-workout.png' alt='focused workouts' />
              </div>
              <h4 className='app-titleh'>Focused Workouts</h4>
              <p className='plan-txt-xsm2'>Designed by fitness experts, these workouts target specific muscle groups and help you get the body you want.</p>
            </div>
            <div className='feature-card col-md-3 position-relative slide-left'>
              <div className='feature-card-logo d-flex flex-column align-self-center'>
                <img className='position-absolute align-self-center' src='../assets/youtube-logo.svg' alt='video play button' />
              </div>
              <h4 className='app-titleh'>Informatory Videos</h4>
              <p className='plan-txt-xsm2'>Get fitness tips, proper exercise forms, and nutrition advice through informative videos on the MyFitnessCoach App.</p>
            </div>
            <div className='feature-card col-md-3 position-relative slide-left'>
              <div className='feature-card-logo d-flex flex-column align-self-center'>
                <img className='position-absolute align-self-center' src='../assets/iphone-in-hand.png' alt='iphone in hand having myfitnesscoachapp' />
              </div>
              <h4 className='app-titleh'>Step Counter</h4>
              <p className='plan-txt-xsm2'>Stay motivated to reach your fitness goals by tracking your daily steps with the built-in step counter.</p>
            </div>
          </div>
        </section>
        <section id="diet-plans-section" className='diet-plans mt-6rem'>
          <Carousel items={1} section="diet-goals" />
        </section>
        <section id="macro-mate-section" className='macro-mate d-flex flex-column align-items-center mt-6rem'>
          <div className='macro-mate-title w-50 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>My Macro Coach</h2>
          </div>
          <div className='macro-mate-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Get access to personalized macro calculations, real-time tracking, and flexible meal planning to revolutionize your nutrition. The My Macro Mate system will optimize your macronutrient intake, enhance energy and performance, increase muscle growth, and promote long-term health and wellness.</p>
          </div>
          {/* Row 1 */}
          <div className='mac-row1 mate-content-wraper d-flex align-items-center cg pt-5'>
            <div className='mate-left col-lg-7 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card'>
              <h3 className='mate-sub-title card-inner-title'>Optimize Your Nutrition Journey</h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm'>Personalized macro calculations tailored to your body composition, activity level, and fitness goals will completely transform your approach to nutrition. Experience the power of precision in fueling your body for maximum performance.</p>
              <div className='mate-body pt-4 position-relative mt-auto'>
                <img className='macro-food1 slide-right' src='assets/nutrition-journey.png' alt='my macro mate feature in my fitnesscoach' />
                <img className='log-food position-absolute slide-left' src='assets/nutrition-journey2.png' />
              </div>
            </div>
            <div className='mate-right col-lg-5 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card' >
              <h3 className='mate-sub-title2 card-inner-title'>Real-Time Tracking And Insights</h3>
              <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Keep track of your progress, log your meals, and gain valuable insights into your macronutrient breakdown in real time. Keep track of your goals, make informed decisions, and maximize your results with this game-changing tool.</p>
              <div className='mate-body2 pt-4 mt-auto'>
                <img className='slide-top' src='assets/tracking-insight.png' alt='my macro mate in iphone' />
              </div>
            </div>
          </div>
          {/* Row 2 */}
          <div className='mac-row2 mate-content-wraper d-flex align-items-center pt-5'>
            <div className='col-lg-4 col-sm-12 px-2 '>
              <div className='mate-left  card-radius-bg px-4 pt-4 d-flex flex-column align-items-center h89vh'>
                <h3 className='mate-sub-title card-inner-title'>Log Food Through Search</h3>
                <p className='mate-sub-description pt-2 plan-txt-xsm'>With <b>MyMacroCoach,</b> log your intake through multiple options</p>
                <div className='mate-body3 pt-4 position-relative mt-auto'>
                  <img className='slide-right w-100' src='assets/log-food-grp.png' alt='my macro mate feature in my fitnesscoach' />
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12 px-2 '>
              <div className='mate-right card-radius-bg px-4 pt-4 d-flex flex-column align-items-center h89vh' >
                <h3 className='mate-sub-title2 card-inner-title'>Log Through Barcode</h3>
                <p className='mate-sub-description2 pt-2 plan-txt-xsm'>With a comprehensive barcode scanner, you can log all your intakes with the barcode. Scan and log your intakes!</p>
                <div className='mate-body4 pt-4 pb-4 mt-auto'>
                  <img className='slide-top log-barcode w-100' src='assets/log-barcode.png' alt='my macro mate in iphone' />
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12 px-2 '>
              <div className='mate-right card-radius-bg px-4 pt-4 d-flex flex-column align-items-center h89vh' >
                <h3 className='mate-sub-title2 card-inner-title'>Create New Recipes or Log Through Quick Calories</h3>
                <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Follow your favorite diets and create your own recipes and log them the way you want.</p>
                <div className='mate-body5 pt-4 mt-auto'>
                  <img className='slide-top w-100' src='assets/log-calories-grp.png' alt='my macro mate in iphone' />

                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='workout-goals-section' className='workout-professionals d-flex flex-column align-items-center mt-6rem position-relative'>
            <div className='wpbp-title w-100 align-self-center pb-2' >
              <h2 className='hero-sub-heading App'>
                <span className='blue-heading-text'>Workouts Performed</span> by Professionals
              </h2>
            </div>
            <div className='wpbp-description w-75 align-self-center'>
              <p className='plan-txt-sm-black App'>MyFitnessCoach can help you find workout routines that match your goals and fitness level. With simple exercises and tailored advice, you can reach your goals and stay motivated to start your journey today!
              </p>
            </div>
            <img className='wpbp-img w-100 mt-4' src='assets/wpbp.png' />
        </section>
        <section id="focused-workouts" className='focused-workouts d-flex align-items-center gap-4 mt-6rem'>
            <div className='focused-left d-flex flex-column gap-2 align-items-start'>
              <div className='fw-title w-100 align-self-center' >
                <h2 className='hero-sub-heading App text-start'>
                  <span className='blue-heading-text'>Focused Workouts</span> <br/> for your every need
                </h2>
              </div>
              <div className='fw-description w-100 align-self-center'>
                <p className='plan-txt-sm-black App text-start'>MyFitnessCoach has an expansive repertoire of workouts ranging from beginners to advanced workouts, while providing various comprehensive workouts that are designed specifically to meet certain fitness goals.
                </p>
              </div>
              <div className='program-cards'>
                  <div className='program-card'>
                    <p>Fat Loss Programs</p>
                  </div>
                  <div className='program-card'>
                    <p>Body Recomposition Programs</p>
                  </div>
                  <div className='program-card'>
                    <p>Muscular Endurance</p>
                  </div>
                  <div className='program-card'>
                    <p>Full-Body Split Programs</p>
                  </div>
                  <div className='program-card'>
                    <p>Strength Training Programs</p>
                  </div>
                  <div className='program-card'>
                    <p>Summer Shred Programs</p>
                  </div>
                  <div className='program-card'>
                    <p>Hypertrophy</p>
                  </div>
                  <div className='program-card'>
                    <p>Home Workouts</p>
                  </div>
                  <div className='program-card'>
                    <p>Muscle Building Programs</p>
                  </div>
                  <div className='program-card'>
                    <p>Body Weight Programs</p>
                  </div>
                  <div className='program-card'>
                    <p>Push, Pull & Legs Workouts</p>
                  </div>
                  <div className='program-card'>
                    <p>Glute Focused Programs</p>
                  </div>
              </div>
            </div>
            <div className='focused-right d-flex align-items-center justify-content-center'>
              <img className='w-100' src='assets/focused-img.png' />
            </div>
        </section>
        <section id='yoga-section' className='yog-section d-flex align-items-center flex-column gap-2 mt-6rem'>
            <div className='yoga-title w-100 align-self-center pb-2' >
              <h2 className='hero-sub-heading App'>
                <span className='blue-heading-text'>Unplug, De-Stress</span> & <span className='blue-heading-text'>Recharge </span> <br/>
                 with MyFitnessCoach
              </h2>
            </div>
            <div className='yoga-description w-75 align-self-center'>
              <p className='plan-txt-sm-black App'>
                MyFitnessCoach not only has an extensive workout library, we also boast to provide different meditation, yoga, Pilates and breathwork exercises. 
              </p>
            </div>
            <div className='yoga-body d-flex w-100'>
              <div className='yoga-card-wrapper ps-0'>
                <div className='yoga-card yc1 d-flex flex-column align-items-center'>
                  <img src='assets/meditation.png'/>
                  <p className='yc-desc'>
                    Experience the soothing benefits of meditation with MyFitnessCoach to reduce stress and enhance mindfulness. Our expertly designed meditation practices will help you achieve mental clarity and stay focused on your goals.
                  </p>
                </div>
              </div>
              <div className='yoga-card-wrapper ps-0'>
                <div className='yoga-card yc2 d-flex flex-column align-items-center'>
                  <img src='assets/breathwork.png'/>
                  <p className='yc-desc'>
                  Experience various breathwork techniques with MyFitnessCoach that help you reduce stress, increase energy, and enhance mental clarity. Our guided sessions are crafted to help you connect deeply with your body and mind.
                  </p>
                </div>
              </div>
              <div className='yoga-card-wrapper ps-0'>
                <div className='yoga-card yc3 d-flex flex-column align-items-center'>
                  <img src='assets/yoga.png'/>
                  <p className='yc-desc'>
                  Embrace the transformative power of yoga with MyFitnessCoach. Our yoga practices are designed to bring harmony to your body and mind, helping you stay centered and focused.
                  </p>
                </div>
              </div>
              <div className='yoga-card-wrapper ps-0 pe-0'>
                <div className='yoga-card yc4 d-flex flex-column align-items-center'>
                  <img src='assets/pilates.png'/>
                  <p className='yc-desc'>
                  Discover tailored Pilates routines with MyFitnessCoach to build strength, flexibility, and balance. Get a leaner, more toned body while enhancing your overall well-being with Pilates workouts.
                  </p>
                </div>
              </div>
            </div>
        </section>
        <section id="workout-loging-mate-section" className='macro-mate d-flex flex-column align-items-center mt-6rem'>
          <div className='macro-mate-title w-50 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>Workout Logging</h2>
          </div>
          <div className='macro-mate-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Track and log workouts with our new Workout Logger to monitor your workout progress effectively.</p>
          </div>
          <div className='mac-row1 mate-content-wraper d-flex align-items-center cg pt-5'>
            <div className='mate-left col-lg-7 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card overflow-hidden'>
              <h3 className='mate-sub-title card-inner-title'>Log Your Workouts Effortlessly</h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm text-justify'>Track, customize and log your workouts with MyFitnessCoach. Input sets, reps, duration, or weight lifted to customize your logging experience. This tool serves as your fitness trainer, allowing you to monitor your workout progress, analyze performance trends, and stay committed to your goals. This tool is equally beneficial whether you're a fitness expert or a beginner.</p>
              <div className='mate-body pt-4 position-relative mt-auto'>
                <img className='macro-food1 slide-right' src='assets/workout-logging.png' alt='my macro mate feature in my fitnesscoach' />
                <img className='log-food position-absolute slide-left' src='assets/workout-logging-2.png' />
              </div>
            </div>
            <div className='mate-right col-lg-5 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card' >
              <h3 className='mate-sub-title2 card-inner-title'>Workout Progression</h3>
              <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Track your workout progress effortlessly with the integrated Workout Logger. Record exercises, monitor weekly progress, and celebrate milestones, all while staying motivated on your fitness journey.</p>
              <div className='mate-body2 pt-4 mt-auto'>
                <img className='slide-top' src='assets/workout-progression.png' alt='workout logging in iphone' />
              </div>
            </div>
          </div>
        </section>
        <section id='track-fitness' className='track-fitness overflow-visible d-flex gap-4 align-items-center mt-6rem'>
          <div className='tf-left position-relative'>
            <img src='assets/track-fitness.png'/>
            <img className='pulse' src='assets/pulse.png'/>
          </div>
          <div className='tf-right'>
            <div className='fw-title w-100 align-self-center' >
                <h2 className='hero-sub-heading App text-start'>
                    <span className='blue-heading-text'>Sync Wearables &  </span> <br/>Track Fitness Journey 
                </h2>
            </div>
            <p className='plan-txt-sm-black text-start'>
            Transform your fitness journey by syncing your wearables with MyFitnessCoach. Our platform not only consolidates all your fitness data but also delivers personalized trends and prompts that accurately reflect your health status. Experience a comprehensive and insightful approach to fitness management with MyFitnessCoach.
            </p>
            <h5 className='card-inner-title'>   
              Information We Track:  
            </h5>
            <div className='tf-ul-wrapper'>
              <ul>
                <li>Daily Steps</li>
                <li>Calories</li>
                <li>Heart Rate</li>
                <li>Distance Travelled</li>
              </ul>
              <ul className='ps-7'>
                <li>Sleep Schedule</li>
                <li>Stress Management</li>
                <li>Blood Oxygen</li>
                <li>Respiratory Rate</li>
              </ul>
            </div>
          </div>
        </section>
        <section id='integrated-devices' className='integrated-devices d-flex flex-column gap-2 align-items-center mt-6rem'>
          <h4 className='id-title'>Integrated Devices</h4>
          <p className='id-desc'>We have successfully integrated wearables using the following apps, and are striving to integrate more soon:</p>
          <div className='id-brands d-flex align-items-center justify-content-between flex-wrap w-100 gap-1'>
            <img src='assets/polar-logo.png'/>
            <div className='id-separator'></div>
            <img src='assets/garmin-logo.png'/>
            <div className='id-separator'></div>
            <img src='assets/fitness+-logo.png'/>
            <div className='id-separator'></div>
            <img src='assets/googleFit-logo.png'/>
            <div className='id-separator'></div>
            <img src='assets/fitbit-logo.png'/>
          </div>
        </section>
        <section id='fitness-tracking' className='fitness-tracking d-flex flex-column gap-3 align-items-center mt-6rem'>
            <div className='ft-title'>
              <p>Fitness Tracking</p>
            </div>
            <CustomAccordian></CustomAccordian>
        </section>
        <section id="body-insights-mate-section" className='macro-mate d-flex flex-column align-items-center mt-6rem'>
          <div className='macro-mate-title w-50 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>Body Insights</h2>
          </div>
          <div className='macro-mate-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Track changes in your body with MyFitnessCoach's new tool. Set personalized goals and work towards your desired body. Start monitoring your progress now!</p>
          </div>
          <div className='mac-row1 mate-content-wraper d-flex align-items-center cg pt-5'>
            <div className='mate-left col-lg-5 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card' >
              <h3 className='mate-sub-title2 card-inner-title'>Log your data</h3>
              <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Customize your fitness journey with accurate measurements for arms, legs, waist, neck, shoulders, and hips. Track your real-time progress through graphs, visually mapping your body transformation over days, weeks, and months. Achieve your body goals with a personalized, data-driven approach.</p>
              <div className='mate-body2 pt-4 mt-auto'>
                <img className='slide-top' src='assets/body-insights-log-data.png' alt='my macro mate in iphone' />
              </div>
            </div>
            <div className='mate-right col-lg-7 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card'>
              <h3 className='mate-sub-title card-inner-title'>Monitor your progress - Make informed decisions</h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm'>Elevate your fitness game with our Body Insights tool – it's more than just numbers; it's your body's exciting story! Set thrilling goals, track progress with clear graphs, and take control of your fitness journey. It's like having a personal coach in your pocket – simple yet powerful.</p>
              <div className='w-100 pt-4 position-relative mt-auto'>
                <img className='macro-food2 slide-right w-100' src='assets/monitor-progress.png' alt='my macro mate feature in my fitnesscoach' />
              </div>
            </div>
          </div>
        </section>
        <section id="weight-management-mate-section" className='macro-mate d-flex flex-column align-items-center mt-6rem'>
          <div className='macro-mate-title w-50 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>Weight Management</h2>
          </div>
          <div className='macro-mate-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Streamline your weight management with MyFitnessCoach. Easily log progress, track trends, and make informed choices for a healthier lifestyle.</p>
          </div>
          <div className='mac-row1 mate-content-wraper d-flex align-items-center cg pt-5'>
            <div className='mate-left col-lg-7 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card overflow-hidden'>
              <h3 className='mate-sub-title card-inner-title'>Keep track of your weight changes</h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm text-justify'>Track your weight with MyFitnessCoach for detailed graphs. Regularly log your weight to see how your lifestyle affects your fitness journey. Monitor progress and make informed decisions to enhance your fitness.</p>
              <div className='w-100 pt-4 position-relative mt-auto'>
                <img className='slide-right w-100 track-weight' src='assets/track-weight.png' alt='my macro mate feature in my fitnesscoach' />
              </div>
            </div>
            <div className='mate-right col-lg-5 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card' >
              <h3 className='mate-sub-title2 card-inner-title'>Log Your Data </h3>
              <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Effortlessly log your weight and monitor your progress weekly, monthly, or yearly. Evaluate your commitment to goal weight with regular logging.</p>
              <div className='mate-body2 pt-4 mt-auto'>
                <img className='slide-top' src='assets/body-insights-log-data.png' alt='my macro mate in iphone' />
              </div>
            </div>
          </div>
        </section>
        <section id="testimonial-section" className='testimonial mt-6rem'>
          <Carousel section="testimonial" items={1} nav={false} />
        </section>
        <section id="info-videos-section" className='info-videos  mt-6rem p-1'>
          <div className='info-vid-head d-flex flex-wrap'>
            <div className='info-vid-title w-100'>
              <h2 className='hero-sub-heading text-center'>Informatory Videos</h2>
            </div>
            {/* <div className='info-vid-link w-50 d-flex justify-content-end'>
              <a className='subscribe-paragraph'>See More at MyFitnessCoach</a>
            </div> */}
            <div className='info-vid-description'>
              <p className='plan-txt-sm-black'>Get access to a library of expert-led videos that provide valuable insights, tips, and guidance on a variety of fitness topics. Become an expert, stay motivated, and make smart decisions to reach your goals.</p>
            </div>
          </div>
          <div className='info-vid-main'>
            <div className='info-vid-left'>
              <div className='intro-right intro-right-2 d-flex justify-content-center align-items-center'>
                <div style={{ position: "relative", width: "80%" }}>
                  <img src="assets/mobile-frame.webp" className="" style={{ height: "100%", width: "100%" }} alt="iphone frame" />
                  <img src="assets/camera-lense.svg" className='camera-lense' alt='iphone camera lense' />
                  <video className="video-fluid informative-video intro-video-2" src="assets/compressed-video.mp4" type="video/mp4" alt="Fitness Trainer"
                    preload="none" controls={true} autoPlay={true} muted={true} playsInline={false} loop={true}
                  />
                </div>
              </div>
            </div>
            <div className='info-vid-right'>
              <div className='ques d-flex'>
                <h3 className='ques-p'>How to lose body fat?</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex'>
                <h3 className='ques-p'>What is Body Fat Percentage</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex'>
                <h3 className='ques-p'>Importance of Rest Days</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex'>
                <h3 className='ques-p'>What is Metabolism?</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex'>
                <h3 className='ques-p'>How to improve your 1Rep Max?</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex border-removed'>
                <h3 className='ques-p'>What is Overtraining?</h3>
                <span>&gt;</span>
              </div>
            </div>
          </div>
        </section >
        <section id="subscription-section" className='subscription d-flex align-items-center mt-6rem p-1'>
          <div className='subs-left  d-flex flex-column rg'>
            <h3 className='subscribe-heading'>Subscribe to Never<br /> Miss Any Update</h3>
            <p className='subscribe-paragraph'>Get the latest info and stay on top! Become a subscriber and get access to exclusive content. Don't miss out, join now.</p>
            <SendEmail />
          </div>
          <div className='subs-right d-flex justify-content-center'>
            <img src='../assets/envelope-email.webp' alt='envelope icon used for subscription' />
          </div>
        </section>
      </div>
    </>

  )
}

export default Home