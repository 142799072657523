import React from 'react';
import { GlobalMethods } from '../services/GlobalMethods';
import './Footer.css';
import { NavLink } from 'react-router-dom';
function Footer() {
  const methods = new GlobalMethods()
  return (
    <footer className="footer mt-6rem">
      <section className='footer-top d-flex container'>
        <div className='footer-left d-flex cg col-lg-5 col-sm-12'>
          <img  src="/assets/nav-logo-sm.png" className="footer-logo" alt="MyFitnessCoach" />
          <div className='d-flex flex-column'>
            <h2 className='footer-heading'>MyFitnessCoach</h2>
            <p className='footer-description'>Start your Fitness Journey Today!</p>
          </div>
        </div>
        <div className='footer-right d-flex cg col-lg-7 col-sm-12 rg'>
          <ul>
            <li className='opacity-remove'><h3 className='footer-subheading '>Home</h3></li>
            <li>
              <NavLink className="" to="/" data-section="intro-section" onClick={methods.handleNavigation}>Introduction to MFC</NavLink>
            </li>
            <li>
              <NavLink className="" to="/" data-section="app-working-section" onClick={methods.handleNavigation}>How it Works?</NavLink>
            </li>
            <li>
              <NavLink className="" to="/" data-section="app-features-section" onClick={methods.handleNavigation}>App Features</NavLink>
            </li>
            <li>
              <NavLink className="" to="/" data-section="diet-plans-section" onClick={methods.handleNavigation}>Diet Plans</NavLink>
            </li>
            <li>
              <NavLink className="" to="/" data-section="workout-goals-section" onClick={methods.handleNavigation}>Workouts</NavLink>
            </li>
            <li>
              <NavLink className="" to="/" data-section="info-videos-section" onClick={methods.handleNavigation}>Informatory Videos</NavLink>
            </li>
            <li>
              <NavLink className="" to="/" data-section="testimonial-section" onClick={methods.handleNavigation}>Testimonials</NavLink>
            </li>
          </ul>
          <ul>
            <li className='opacity-remove'><h3 className='footer-subheading'>Resources</h3></li>
            <li><NavLink className="" to="/blogs/all" target='_blank'>Blogs</NavLink></li>
            <li><NavLink className="" to="/terms-and-conditions" target='_blank'>Terms & Conditions</NavLink></li>
            <li><NavLink className="" to="/privacy-policy" target='_blank'>Privacy Policy</NavLink></li>
            <li><NavLink className="" to="/about-us" target='_blank'>About Us</NavLink></li>
          </ul>
          <ul>
            <li className='opacity-remove'><h3 className='footer-subheading'>Contact Us</h3></li>
            <li><a href="https://mail.google.com/mail/u/0/?fs=1&to=info@myfitnesscoach.fit&su=&body=&tf=cm" target='_blank'>info@myfitnesscoach.fit</a></li>
          </ul>
        </div>
      </section>
      <section className='footer-bottom d-flex container align-items-center'>
        <div className='fbottom-left col-md-6 col-sm-12'>
          <p className='follow-us'>Follow Us  at:</p>
          <div className='footer-social d-flex'>
            <a className='social-img-fluid' href='https://twitter.com/mfc_app' target='_blank'><img  src='/assets/footer-twitter.svg' alt='twitter icon' /></a>
            <a className='social-img-fluid' href='https://www.facebook.com/myfitnesscoachofficial' target='_blank'><img  src='/assets/footer-fb.svg' alt='facebook icon' /></a>
            <a className='social-img-fluid' href='https://www.instagram.com/myfitnesscoachofficial/' target='_blank'><img  src='/assets/footer-insta.svg' alt='instagram icon' /></a>
            <a className='social-img-fluid' href='https://www.tiktok.com/@myfitnesscoachofficial' target='_blank'><img  src='/assets/footer-ticktok.svg' alt='tiktok icon' /></a>
            <a className='social-img-fluid' href='https://www.youtube.com/@myfitnesscoachofficial' target='_blank'><img  src='/assets/yt-icon-new.svg' alt='youtube icon' /></a>
            <a className='social-img-fluid' href='https://www.pinterest.com/myfitnesscoachofficial/' target='_blank'><img  src='/assets/footer-printrest.svg' alt='printerest icon' /></a>
            <a className='social-img-fluid' href='https://www.linkedin.com/company/myfitnesscoachofficial/' target='_blank'><img  src='/assets/footer-linkedin.svg' alt='linkedin icon' /></a>
          </div>
        </div>
        <div className='fbottom-left col-md-6 col-sm-12'>
          <p className='footer-copyrights'>© Copyright 2023, All Rights Reserved</p>
        </div>
      </section>
    </footer>
  )
}
export default Footer