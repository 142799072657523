import React, { useEffect } from 'react';
import { GlobalMethods } from '../services/GlobalMethods';
import MetaTags from '../components/utils/MetaTags';
export default function PrivacyPolicy() {
    const methods = new GlobalMethods()
    GlobalMethods.current_sec = "privacy-policy";
    useEffect(() => {
        setTimeout(() => {
            methods.snapTo(GlobalMethods.current_sec)
        }, 500);
    }, [])
    return (
        <>
            <MetaTags text="MyFitnessCoach | My Fitness Coach"/>
            <main className="px-responsive-5">
                <section id="privacy-policy" className='about-who-we-are'>
                    <div style={{ backgroundColor: "#37395D;" }}>
                        <div className="secondary-wrapper blogs-container">
                            <div className="container container-padding-fluid">
                                <p className='blogs-heading text-start' >
                                    Privacy Policy for MyFitnessCoach
                                </p>
                                <p className='card-mid-p'>At MyFitnessCoach <a href='https://myfitnesscoach.fit' target='_blank'>https://myfitnesscoach.fit</a>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that are collected and recorded by MyFitnessCoach and how we use it.
                                    If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                                    This Privacy Policy applies only to our online activities and is valid for visitors to our website with regard to the information that they shared and/or collected in MyFitnessCoach. This policy is not applicable to any information collected offline or via channels other than this website.
                                </p>
                                <p className="blogs-h3"><b>Consent</b></p>
                                <p className='card-mid-p'>By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                                </p>
                                <p className="blogs-h3"><b>Information We Collect</b></p>
                                <p className='card-mid-p'>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                                </p>
                                <p className='card-mid-p'>
                                    If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                                </p>
                                <p className='card-mid-p'>
                                    When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                                    How We Use Your Information
                                </p>
                                <p className='card-mid-p'><b>We use the information we collect in various ways, including:</b></p>
                                <ul className='blogs-list'>
                                    <li>Provide, operate, and maintain our website</li>
                                    <li>Improve, personalize, and expand our website
                                    </li>
                                    <li>Understand and analyze how you use our website</li>
                                    <li>Develop new products, services, features, and functionality</li>
                                    <li>Send you emails</li>
                                    <li>Find and prevent fraud</li>
                                    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                </ul>
                                <p className="blogs-h3"><b>Cookies </b></p>
                                <p className='card-mid-p'>Like any other website, MyFitnessCoach uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
                                <p className='blogs-h3'><b>Third-Party Privacy Policies</b></p>
                                <p className='card-mid-p'>MyFitnessCoach's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt out of certain options.
                                    You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found on the browsers' respective websites.
                                </p>
                                <p className='blogs-h3'><b>Do Not Sell My Personal Information</b></p>
                                <p className='card-mid-p'>Under the USA Consumer Protection Act, among other rights, consumers have the right to:
                                </p>
                                <ul className='blogs-list'>
                                    <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
                                    <li>Request that a business delete any personal data about the consumer that a business has collected.
                                    </li>
                                    <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
                                    <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please <a href="https://mail.google.com/mail/u/0/?fs=1&to=info@myfitnesscoach.fit&su=&body=&tf=cm" target='_blank'>contact us</a>.</li>
                                </ul>
                                <p className='blogs-h3'><b>Changes to This Privacy Policy</b></p>
                                <p className='card-mid-p'>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
                                </p>
                                <p className='blogs-h3'><b>Contact Us</b></p>
                                <p className='card-mid-p'>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at&nbsp;<a href="https://mail.google.com/mail/u/0/?fs=1&to=info@myfitnesscoach.fit&su=&body=&tf=cm" target='_blank'>info@myfitnesscoach.fit</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>
    )
}