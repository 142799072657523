import * as React from "react";
import '../utils/Carousel.css';
import ACarousel from "./AliceCarousel";

export default function Carousel(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const autoPlay = true;
  return (
    props?.section == "diet-goals" ?
      (
        <ACarousel showDots hideNav items="1" data={[
          <div className='item c-diet-plan position-relative d-flex'>
            <div className='diet-body d-flex flex-column align-items-center rg overflow-hidden'>
              <img  className='recipies-grp' src='assets/recipies-grp.webp' alt="healthy recipes" />
              <h3 className='heading-64px '>Curated Diet Plans</h3>
              <p className='plan-txt-sm-black text-center'>Discover diet plans tailored to your specific needs and goals. We have designed these plans in consultation with a team of nutritionists and dietitians to create a well-balanced and nutritious approach to help you achieve the best health and fitness possible.</p>
            </div>
          </div>,
          <div className='item healthy-recipe position-relative d-flex'>
            <div className='recipe-body d-flex cg align-items-center'>
              <div className='recipy-left col-lg-6 col-md-12 d-flex flex-column rg align-items-center '>
                <img  className='healthy-grp' src='assets/healthy-recipe-grp.png' alt="healthy diet" />
                <h3 className='heading-64px'>Healthy Recipes for you to Explore</h3>
                <p className='plan-txt-sm-black text-start'>Wide range of recipes to make sure you get the most out of your diet plan. Our catalog contains <b>950+</b> recipes making sure you get the best results.</p>
              </div>
              <div className='recipy-right col-lg-6 col-md-12 '>
                <img  className='meal-grp' src='assets/meal-plan-grp.webp' alt="healthy meal" />
              </div>
            </div>
          </div>
        ]
        } />
      )
      :
      props?.section == "workout-goals" ?
        (
          <ACarousel showDots hideNav items="1" data={[
            <div className='item workout-slide1 d-flex align-items-center justify-content-center'>
              <div className='workout-body-wraper d-flex align-items-center '>
                <div className='workout-left col-lg-7 d-flex flex-column '>
                  <h3 className="heading-48px-white d-flex flex-row m-0">New & &nbsp;
                    <span className="text-logo d-flex flex-column position-relative">
                      <h3 className="heading-48px-white">Challenging </h3>
                      <img  src="assets/text-logo2.svg" alt="underline" className="challenge-dash w-auto position-absolute" />
                    </span>
                  </h3>
                  <h3 className="heading-48px-white">Workouts</h3>
                  <p className='plan-txt-white pt-4'>Take your fitness journey to the next level with a variety of exciting and challenging workouts. We offer a variety of innovative workout programs that are continuously updated, ensuring that your exercise routine remains fresh and enjoyable.</p>
                </div>
                <div className='workout-right col-lg-5 d-flex justify-content-end '>
                  <img  className='aaron-ag w-auto ' src='assets/workout-goals-grp.png' alt="workout goals" />
                </div>
              </div>
            </div>,
            <div className='item workout-slide2 d-flex align-items-center justify-content-center'>
              <div className='workout2-body-wraper d-flex align-items-center justify-content-center position-relative '>
                <div className='workout2-left col-lg-7 col-sm-12 '>
                  <h3 className='heading-48px-white'>Choose Workouts to Match Your Goals</h3>
                  <p className='plan-txt-white pt-4'>MyFitnessCoach can help you find workout routines that match your goals and fitness level. With simple exercises and tailored advice, you can reach your goals and stay motivated to start your journey today!</p>
                </div>
                <div className='workout2-right col-lg-5 col-sm-12 d-flex align-items-center justify-content-center'>
                  <div className='workout-img-wraper position-relative'>
                    <img  className='home-lifting w-100 ' src='assets/home-lifting-grp.png' alt="man lifting dumbbells" />
                  </div>
                </div>

              </div>
            </div>,
            <div className='item workout-slide3'>
              <div className='workout3-body-wraper d-flex align-items-baseline'>
                <div className='workout3-left col-lg-6 col-sm-12 pe-3 '>
                  <h3 className='hero-sub-heading'>Workouts Performed by Professionals</h3>
                  <p className='plan-txt black-light pt-4'>MyFitnessCoach offers a personalized approach to fitness, and provides access to certified professionals to help you get the most out of your workouts.</p>
                </div>
                <div className='workout3-right col-lg-6 col-sm-12 px-3 '>
                  <img  className='exercise-grp w-100 d-inline-block ' src='assets/exercise-grp.webp' alt="workouts" />
                </div>
              </div>
            </div>
          ]
          } />
        )
        :
        props?.section == "testimonial" ?
          (
            <ACarousel showDots hideNav items="1" data={[
              <div className='item testo-slide '>
                <p className="testo-text ">"As someone who travels frequently, MyFitnessCoach has been a lifesaver. The app offers on-the-go workouts that don't require equipment, making it easy for me to stay active even when I'm away from the gym. The video demonstrations are clear, and the workout plans are challenging enough to keep me engaged. The only downside is that sometimes the exercise library could use more variety. However, overall, it's an excellent fitness app that keeps me motivated while on the road."</p>
                <p className="testo-aurthor ">Ryan Miller </p>
              </div>,
              <div className='item testo-slide '>
                <p className="testo-text ">"MyFitnessCoach has completely changed my fitness approach. The app's workout plans are well-structured and effective. The variety of exercises keeps me engaged, and progress tracking helps me see how far I've come. I appreciate the app's integration with nutrition tracking, allowing me to monitor my calorie intake and make healthier choices. The user community is supportive and inspiring, providing an extra boost of motivation. MyFitnessCoach is worth every penny!"</p>
                <p className="testo-aurthor ">Olivia Turner </p>
              </div>,
              <div className='item testo-slide '>
                <p className="testo-text ">"I've been using MyFitnessCoach for a few weeks now, and I'm impressed with the app's features. The workout plans are suitable for all fitness levels, and the video demonstrations ensure correct performance. I like the option to create custom workouts tailored to my specific needs. The app's interface is intuitive and easy to navigate. The only suggestion I have is to add more variety to the exercise library. However, overall, it's a reliable fitness app that I enjoy using."</p>
                <p className="testo-aurthor ">Benjamin Martinez  </p>
              </div>,
              <div className='item testo-slide '>
                <p className="testo-text ">"MyFitnessCoach has been a game-changer on my fitness journey. The app is easy to navigate, and the workout plans are tailored to my fitness level and goals. Exercise demonstrations and videos are clear and helpful, ensuring correct performance. I also appreciate the variety of workout options available, from strength training to yoga. The only reason I didn't give it five stars is that occasionally the app can be slow to load, but overall, it's a fantastic fitness companion."</p>
                <p className="testo-aurthor ">Sarah Johnson  </p>
              </div>,
              <div className='item testo-slide '>
                <p className="testo-text ">"MyFitnessCoach has helped me stay motivated and consistent with my workouts. The app's workout tracking feature keeps me accountable, and I can easily monitor my progress over time. Integration with wearable devices is a plus, as it automatically syncs my workout data. The app also offers challenges and rewards, making fitness fun. The only improvement I would suggest is adding more variety to the workout plans. However, overall, it's a reliable fitness app that I highly recommend."</p>
                <p className="testo-aurthor ">Emily Ramirez   </p>
              </div>,
              <div className='item testo-slide '>
                <p className="testo-text ">"I've been using MyFitnessCoach for a few months now, and I'm extremely satisfied with the results. The app's workout plans are well-designed, challenging but doable. Trainers provide clear instructions and modifications for different fitness levels. The app's community feature allows me to connect with like-minded individuals, sharing tips and motivation. Nutritional tracking is comprehensive, helping me make healthier choices. MyFitnessCoach has become an essential tool in my fitness routine."</p>
                <p className="testo-aurthor ">David Thompson  </p>
              </div>
            ]
            } />
          )
          :
          ""
  );
}
